<template>
  <div class="photoPage" @click.stop="photoPage">
    <el-row :gutter="20" class="title">
      <el-col
          :span="12"
          style="border-right: 1px solid rgba(187, 187, 187, 100);"
      >图片信息
      </el-col
      >
      <el-col :span="12">取景区域</el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12" class="left-box">
        <el-row class="top-text">
          <div>拍照规范，请仔细阅读</div>
          <div>
            ①请严格按照图片示例进行拍照，并确保照片的清晰度；②.衣物特殊瑕疵部位，单独拍照记录；
          </div>
          <div>
            ③.如衣物款式与示例不符，以实物为淮；④.如客户等待较多，时间较久，可忽略拍照，收银后，再单独进行补拍。
          </div>
        </el-row>
        <el-row class="table-box">
          <el-table
              :data="tableData"
              :cell-style="setCellStyle"
              :show-header="false"
              :span-method="objectSpanMethod"
              border
          >
            <el-table-column prop="name" width="80"></el-table-column>
            <el-table-column prop="amoAskedDescribe" width="120">
            </el-table-column>
            <el-table-column prop="describeContent"></el-table-column>
          </el-table>
        </el-row>
        <el-row class="example-img" v-if="exampleImages">
          <div
              v-for="(item, index) in exampleImages"
              :key="index"
              style="width:20%;text-align:center"
          >
            <div>
              <el-image
                  class="img"
                  :src="item.exampleImage"
                  :preview-src-list="exampleImagesList"
              >
              </el-image>
              <div style=" margin-bottom: 10px;text-align: center;">
                {{ item.exampleName }}
              </div>
            </div>
          </div>
        </el-row>
        <el-row style="padding-bottom:20px;padding-left:15px">
          <span class="el-icon-view icon"></span><span>查看图片</span>
          <el-input
              type="text"
              ref="input"
              :autofocus="true"
              style="width:100px;position: absolute;opacity: 0;"
              @keyup.native="enterKey"
          ></el-input>
        </el-row>
        <el-row class="photograph-img" v-if="images">
          <div v-for="(item, index) in images" :key="index" style="width:14%;">
            <div style="width:90px;position: relative">
              <el-image
                  class="img"
                  :src="item.url"
                  :preview-src-list="imagesList"
              >
              </el-image>
              <div style="padding-left:20px;margin-bottom:15px">
                图片{{ index + 1 }}
              </div>
              <el-button
                  type="danger"
                  size="mini"
                  class="del"
                  icon="el-icon-delete"
                  circle
                  @click="toDelete(item.url)"
              ></el-button>
            </div>
          </div>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row class="photograph">
          <div class="camera_outer">
            <video id="videoCamera" class="videoCamera" autoplay></video>
            <canvas
                id="canvasCamera"
                class="canvasCamera"
                ref="videoCamera"
            ></canvas>
          </div>
        </el-row>
        <el-row class="photograph-button">
          <el-button type="primary" :loading="imageLoading" @click="setImage()"
          >拍 照
          </el-button
          >
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OSS from "ali-oss";
import {getAliYunSts} from "@/api/file/ali";
import {getPhotoExampleDetail} from "@/api/clothes/clothes.js";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      tableData: [
        {
          name: "",
          amoAskedDescribe: "拍照要求",
          describeContent: "按拍照示例进行拍照",
        },
        {
          name: "",
          amoAskedDescribe: "检查部位",
          describeContent: "",
        },
        {
          name: "",
          amoAskedDescribe: "检查瑕疵",
          describeContent: "",
        },
      ],
      exampleImages: [],
      exampleImagesList: [],
      images: [],
      imageLoading: false,
      aliSts: {},
      thisCanvas: null,
      thisContext: null,
      thisVideo: null,
      iconShow: false,
      imagesList: [],
      imageObj: {},
      imageId: null,
      keydownShow: true,
      toDeleteImg: false,
    };
  },
  mounted() {
    this.getCompetence();
    this.$nextTick(() => {
      this.$refs.input.focus();
    });
  },
  created() {
    this.getPhotoExampleDetailData();
    this.setImages();
  },
  watch: {
    toDeleteImg(value, oldvalue) {
      console.log(value, oldvalue);
      if (!value) {
        this.$refs.input.focus();
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    setImages() {
      this.imageId = window.location.href.slice(
          window.location.href.lastIndexOf("=") + 1,
          window.location.href.length
      );
      let imageObj = JSON.parse(window.localStorage.getItem("imageObj"));
      this.imageObj = imageObj;
      if (imageObj && imageObj["image" + this.imageId]) {
        this.images = imageObj["image" + this.imageId];
        this.imagesList = this.images.map((item) => item.url);
      }
    },
    // 获取拍照示例信息
    getPhotoExampleDetailData() {
      this.loading = true;
      let id = window.location.href.slice(
          window.location.href.indexOf("=") + 1,
          window.location.href.indexOf("&")
      );
      getPhotoExampleDetail(id).then(({data}) => {
        if (data.code == 0) {
          console.log(data);
          this.exampleImages = data.data?.photoExampleDetailList;
          this.exampleImagesList = data.data?.photoExampleDetailList.map(
              (item) => item.exampleImage
          );
          this.tableData = this.tableData.map((item, index) => {
            item.name = data.data?.clothesName;
            if (index == 1) {
              item.describeContent = data.data?.checkParts;
            } else if (index == 2) {
              item.describeContent = data.data?.checkFlaw;
            }
            return item;
          });
          this.loading = false;
        } else {
          this.$message.error(data.msg);
          this.loading = false;
        }
      });
    },
    setCellStyle({column}) {
      if (column.property == "amoAskedDescribe" || column.property == "name") {
        return "text-align: center";
      }
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    // 获取阿里云oss sts
    getAliYunSts() {
      getAliYunSts().then((response) => {
        this.aliSts = response.data.data;
      });
    },
    // 调用权限（打开摄像头功能）
    getCompetence() {
      // 获取阿里云配置
      this.getAliYunSts();
      const _this = this;
      this.$nextTick(() => {
        this.thisCanvas = document.getElementById("canvasCamera");
        this.thisContext = this.thisCanvas.getContext("2d");
        this.thisVideo = document.getElementById("videoCamera");
        // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {};
        }
        // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            var getUserMedia =
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.getUserMedia;
            // 有些浏览器不支持，会返回错误信息
            // 保持接口一致
            if (!getUserMedia) {
              return Promise.reject(
                  new Error("getUserMedia is not implemented in this browser")
              );
            }
            // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }
        setTimeout(() => {
          var constraints = {
            audio: false,
            video: {
              transform: "scaleX(-1)",
            },
          };
          navigator.mediaDevices
              .getUserMedia(constraints)
              .then(function (stream) {
                // 旧的浏览器可能没有srcObject
                if ("srcObject" in _this.thisVideo) {
                  _this.thisVideo.srcObject = stream;
                } else {
                  // 避免在新的浏览器中使用它，因为它正在被弃用。
                  _this.thisVideo.src = window.URL.createObjectURL(stream);
                }
                _this.thisVideo.onloadedmetadata = function (e) {
                  _this.thisVideo.play();
                };
              })
              .catch((err) => {
                console.log(err);
              });
        }, 0);
      });
    },
    //  绘制图片（拍照功能）
    setImage() {
      this.imageLoading = true;
      let _this = this;
      // 点击，canvas画图
      this.thisCanvas.setAttribute("width", window.innerWidth * 0.6);
      this.thisCanvas.setAttribute("height", window.innerHeight * 0.75);

      _this.thisContext.drawImage(
          _this.thisVideo,
          0,
          0,
          window.innerWidth * 0.6,
          window.innerHeight * 0.75
      );
      this.thisContext.font = "24px 宋体";
      this.thisContext.fillStyle = "red";
      // 设置右对齐
      this.thisContext.textAlign = "right";
      // 在指定位置绘制文字，这里指定距离右下角20坐标的地方
      let time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let storeName = this.$store.state.user.userInfo.storeName;
      this.thisContext.fillText(
          storeName + "  " + time,
          this.thisCanvas.width - 20,
          this.thisCanvas.height - 20
      );
      // 获取图片base64链接
      let image = this.thisCanvas.toDataURL("image/jpeg", 1.0);

      if (_this.images.length < 30) {
        this.toUpload(image);
      } else {
        this.$message.error("最多只能上传和拍摄30张照片！");
        this.imageLoading = false;
      }
    },
    // 关闭摄像头
    stopNavigator() {
      if (this.thisVideo != null) {
        this.thisVideo.srcObject.getTracks()[0].stop();
      }
    },
    dataURItoBlob(base64Data) {
      //console.log(base64Data);//data:image/png;base64,
      var byteString;
      if (base64Data.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(base64Data.split(",")[1]);
      //base64 解码
      else {
        byteString = unescape(base64Data.split(",")[1]);
      }
      var mimeString = base64Data
          .split(",")[0]
          .split(":")[1]
          .split(";")[0]; //mime类型 -- image/png

      // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
      // var ia = new Uint8Array(arrayBuffer);//创建视图
      var ia = new Uint8Array(byteString.length); //创建视图
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ia], {
        type: mimeString,
      });
      return blob;
    },

    // 上传图片
    async toUpload(urlData) {
      let _this = this;
      let client = new OSS({
        region: _this.aliSts.region,
        accessKeyId: _this.aliSts.accessKeyId,
        accessKeySecret: _this.aliSts.accessKeySecret,
        stsToken: _this.aliSts.stsToken,
        bucket: _this.aliSts.bucket,
      });
      let blob = this.dataURItoBlob(urlData);
      let random_name = "cashier_system/collect_clothes_photo/" + this.userInfo.storeId + "/" + new Date().getTime() + "." + "jpeg";
      client
          .put(random_name, blob)
          .then((result) => {
            _this.imageLoading = false;
            // _this.$customAlert({message: '保存成功'})
            if (_this.images.length < 30) {
              _this.images.push({
                url: _this.aliSts.domain + "/" + random_name,
                name: random_name,
                bool: true,
              });
              _this.imagesList = _this.images.map((item) => item.url);
              _this.imageObj["image" + _this.imageId] = _this.images;
              window.localStorage.setItem(
                  "imageObj",
                  JSON.stringify(_this.imageObj)
              );
            }
          })
          .catch((err) => {
            console.log("错误", err);
          });
    },
    // 参数: base64地址,压缩比例，回调函数(返回压缩后图片的blob和base64)
    compressPic: function (base64, scale, callback) {
      const that = this;
      let _img = new Image();
      _img.src = base64;
      _img.onload = function () {
        let _canvas = document.createElement("canvas");
        _canvas.setAttribute("width", window.innerWidth - 200);
        _canvas.setAttribute("height", window.innerHeight - 200);
        _canvas
            .getContext("2d")
            .drawImage(
                this,
                0,
                0,
                window.innerWidth - 200,
                window.innerHeight - 200
            );

        let base64 = _canvas.toDataURL("image/jpeg", 1);
        // 当canvas对象的原型中没有toBlob方法的时候，手动添加该方法
        if (!HTMLCanvasElement.prototype.toBlob) {
          Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
            value: function (callback, type, quality) {
              let binStr = atob(this.toDataURL(type, 1).split(",")[1]),
                  len = binStr.length,
                  arr = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
              }
              callback(new Blob([arr], {type: type || "image/png"}));
            },
          });
        } else {
          _canvas.toBlob(function (blob) {
            if (blob.size > 1024 * 1024) {
              that.compressPic(base64, scale, callback);
            } else {
              callback(blob, base64);
            }
          }, "image/jpeg");
        }
      };
    },
    // 删除图片
    toDelete(fileName) {
      this.toDeleteImg = true;
      this.$confirm("确认删除图片, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let _this = this;
            let client = new OSS({
              region: this.aliSts.region,
              accessKeyId: this.aliSts.accessKeyId,
              accessKeySecret: this.aliSts.accessKeySecret,
              stsToken: this.aliSts.stsToken,
              bucket: this.aliSts.bucket,
            });
            let fileName1 = fileName.substring(
                fileName.indexOf(this.aliSts.domain) + this.aliSts.domain.length + 1
            );
            client
                .delete(fileName1)
                .then((result) => {
                  this.$message.success("删除成功");
                  _this.images = _this.images.filter(
                      (item) => item.url != fileName
                  );
                  _this.imagesList = _this.images.map((item) => item.url);
                  _this.imageObj["image" + _this.imageId] = _this.images;
                  window.localStorage.setItem(
                      "imageObj",
                      JSON.stringify(_this.imageObj)
                  );
                  this.toDeleteImg = false;
                })
                .catch((err) => {
                  console.log("错误", err);
                });
          })
          .catch(() => {
            this.$message.info("取消删除");
            this.toDeleteImg = false;
          });
    },
    enterKey(e) {
      if (e.keyCode == 32) {
        this.setImage();
      }
    },
    photoPage(e) {
      e.preventDefault();
      this.$refs.input.focus();
      // this.toDeleteImg=false
    },
  },
};
</script>

<style lang="scss" scoped>
.camera_outer {
  height: 98%;
  position: relative;
  top: 0;
  left: 0;

  .videoCamera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: 999;
  }

  .canvasCamera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.photoPage {
  width: calc(100vw - 12px);
}

/*里面的代码可以根据自己需求去进行更改*/
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

.left-box {
  height: calc(100vh - 75px);
  overflow: auto;
}

.title {
  height: 50px;
  line-height: 50px;
  background-color: rgba(22, 132, 252, 100);
  text-align: center;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
}

.top-text {
  font-size: 12px;
  border-radius: 5px;
  color: rgba(16, 16, 16, 100);
  background-color: rgba(200, 250, 254, 100);
  margin: 10px 5px;
  padding: 10px 10px 5px;

  div {
    padding-bottom: 5px;
  }
}

.table-box {
  margin: 0 5px 10px;
}

.example-img {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 10px 20px;
}

.img {
  width: 90px;
  height: 90px;
  // padding-right: 20px;
  // padding-bottom: 10px;
}

.icon {
  padding-right: 10px;
  color: #3370FF;
}

.photograph-img {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}

.photograph-button {
  height: 100px;
  line-height: 100px;
  text-align: center;

  .el-button {
    width: 40vw;
    height: 60px;
    font-size: 20px;
  }
}

.photograph {
  height: calc(100vh - 175px);
}

.del {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
</style>
